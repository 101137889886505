import './App.css';
import Reader from './components/Reader';

function App() {
    return (
        <div className="App">
            <Reader />
        </div>
    );
}

export default App;
