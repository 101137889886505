import { useEffect, useState } from "react";
import { SignIn } from "../interfaces/SignIn";
import { Row } from "../interfaces/Row";
import { User } from "../interfaces/User";

const Reader = () => {
    const [err, setErr] = useState<string | undefined>();
    const [signIns, setSignIns] = useState<SignIn[]>([]);
    const [dates, setDates] = useState<string[]>([]);
    const [users, setUsers] = useState<User[]>([]);
    const DATE_COLUMN_NAME = 'Data (UTC)';
    const IP_COLUMN_NAME = 'Adres IP';
    const ID_COLUMN_NAME = 'Identyfikator użytkownika';
    const USER_COLUMN_NAME = 'Użytkownik';
    const OFFICE_IP = '213.189.43.102';

    const translateDayOfWeek = (day: number): string => {
        switch(day) {
            case 0: return 'ND';
            case 1: return 'PN';
            case 2: return 'WT';
            case 3: return 'ŚR';
            case 4: return 'CZ';
            case 5: return 'PT';
            case 6: return 'SB';
            default: return '';
        }
    }

    const getFullDate = (date: Date): string => {
        return `${('0' + date.getDate()).slice(-2)}-${('0' + (date.getMonth() + 1)).slice(-2)} (${translateDayOfWeek(date.getDay())})`;
    }

    const removeRedundantRows = async(rows: string[][], dateIndex: number, ipIndex: number, idIndex: number, userIndex: number): Promise<Row[]> => {
        const users: User[] = [];
        const dates = new Set<string>();
        const filteredRows = rows.filter(e => e[dateIndex] && e[ipIndex] && e[idIndex] && e[userIndex]).map(e => {
            const user = {id: e[idIndex], name: e[userIndex]};
            const date = new Date(e[dateIndex]);
            if(!users.find(e => e.id === user.id))
                users.push(user);
            dates.add(getFullDate(date));
            return {
                date: date,
                ip: e[ipIndex],
                user: user
            };
        });
        setUsers(users.sort((u1, u2) => u1.name.localeCompare(u2.name)));
        setDates([...dates].reverse());
        return filteredRows;
    }

    const parseRows = async (rows: string): Promise<string[][]> => {
        return rows.replaceAll('\r', '').split('\n').map(e => e.split('","').map(e => e.trim().replaceAll('"', '').replaceAll('\'', '').trim()));
    }

    const getSignIns = async (rows: Row[]): Promise<SignIn[]> => {
        const signIns: SignIn[] = [];
        rows.forEach(e => {
            let element: SignIn | undefined = signIns.find(s => s.date.toDateString() === e.date.toDateString() && s.user.id === e.user.id);
            if(!element) {
                element = {
                    user: e.user,
                    date: e.date,
                    isOffice: false,
                };
                signIns.push(element);
            }
            if(e.ip === OFFICE_IP) {
                element.isOffice = true;
            }
        });
        return signIns;
    }

    const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        const file: File | undefined = e.target.files[0];
        console.log(file);
        if(file?.type === 'text/csv') {
            setErr(undefined);
            const rows = await file.text();
            const parsedRows = await parseRows(rows);
            parsedRows.forEach(e => {
                console.log(e);
            });
            const dateIndex = parsedRows[0].findIndex(e => e === DATE_COLUMN_NAME);
            if(dateIndex == null) {
                setErr('Nie można znaleźć nagłówka ' + DATE_COLUMN_NAME);
                return;
            }
            const ipIndex = parsedRows[0].findIndex(e => e === IP_COLUMN_NAME);
            if(ipIndex == null) {
                setErr('Nie można znaleźć nagłówka ' + IP_COLUMN_NAME);
                return;
            }
            const idIndex = parsedRows[0].findIndex(e => e === ID_COLUMN_NAME);
            if(idIndex == null) {
                setErr('Nie można znaleźć nagłówka ' + ID_COLUMN_NAME);
                return;
            }
            const userIndex = parsedRows[0].findIndex(e => e === USER_COLUMN_NAME);
            if(userIndex == null) {
                setErr('Nie można znaleźć nagłówka ' + USER_COLUMN_NAME);
                return;
            }
            const result = await removeRedundantRows(parsedRows.splice(1), dateIndex, ipIndex, idIndex, userIndex);
            console.log(result.map(e => {return {...e,
                date: e.date.toDateString()
            }}));
            console.log(dateIndex);
            console.log(idIndex);
            console.log(userIndex);
            console.log(ipIndex);
            const signIns = await getSignIns(result);
            setSignIns(signIns);

        } else {
            setErr('Plik musi być typu csv');
        }
      }
    };

    useEffect(() => {
        console.log(signIns);
        console.log(users);
        console.log(dates);
    }, [dates, users]);
  

    
    return (
        <main className="flex flex-col justify-center gap-6 text-center">
            <div className="w-[100%] flex justify-center items-center py-8">
                <label htmlFor="file-upload" className="custom-file-upload">
                    <span className="text-white bg-green-500 py-2 px-2 rounded-md cursor-pointer">Wgraj plik csv</span>
                </label>
                <input id="file-upload" type="file" onChange={handleFileChange} className="hidden" />
                {err && <p className="text-red-600 font-bold">{err}</p>}
            </div>
            <div>
                {signIns.length > 0 && 
                    <table className="w-[100%]">
                        <thead className="sticky">
                            <tr>
                                <th>Imie i nazwisko</th>
                                {dates.map(e => <th>{e}</th>)}
                            </tr>
                        </thead>
                        <tbody>
                            {users.map(e => <tr>
                                    <td>
                                        {e.name}
                                    </td>
                                    {dates.map(d => 
                                        <td>
                                            {signIns.find(s => s.user.id === e.id && getFullDate(s.date) === d) == null ? '' : signIns.find(s => s.user.id === e.id && getFullDate(s.date) === d)?.isOffice ? 'BIURO' : 'Zdalnie'}
                                        </td>)
                                    }
                                </tr>)
                            }
                        </tbody>
                    </table>
                }

            </div>
        </main>
    );
}

export default Reader;